.trip-routes-header {
    width: 80%;
}

.is-display-yes {
    display: '';
}

.is-display-no {
    display: none;
}

.main-margin {
    margin-top: 34px !important;
}

.card-padding {
    padding: 15px;
}

.trip-header-font {
    font-family: 'Celebes-semi-bold' !important;
}

.trip-grid {
    display: flex !important;
    justify-content: center !important;
}

@media only screen and (max-width: 900px)  {
    .trip-routes-header {
        width: 100%;
    }
    .main-container {
        margin-top: 10px !important;
    }
    .main-margin {
        margin-top: 0px !important;
    }
    .card-padding  {
        padding: 0;
    }
    .trip-grid {
        justify-content: start !important;
    }
}
