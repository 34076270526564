@font-face {
  font-family: "Celebes";
  src: local("Celebes"),
    url("./fonts/celebes/Celebes-Bold-BF6400136f4d72e.otf") format("truetype");
}

@font-face {
  font-family: "Celebes-regular";
  src: local("Celebes"),
    url("./fonts/celebes/Celebes-Regular-BF6400136f4bb02.otf") format("truetype");
}

@font-face {
  font-family: "Celebes-semi-bold";
  src: local("Celebes"),
    url("./fonts/celebes/Celebes-SemiBold-BF6400136f9794c.otf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Celebes-regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Celebes-regular';
}