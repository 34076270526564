.loader {
	width: 140px;
	height: 140px;
	position: relative;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite;
	margin-bottom: 20px;
}
.loader::before {
	content: '';
	position: absolute;
	top: 15px;
	left: 18px;
	width: 18px;
	height: 18px;
}

.header-padding-bottom {
	padding-bottom: 70px;
}

.trip-routes-container {
	background-color: var(--conatiner--bg-color) !important;
	padding: '20px';
}

.loader-container {
	background-color: var(--loader--bg-color) !important;
}

.loader-text {
	color: var(--loader-text-color) !important;
	font-weight: bold !important;
}

@media only screen and (max-width: 900px)  {
	.trip-routes-container {
		padding: '10px';
	}	
}

@keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

@-webkit-keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}