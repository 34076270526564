.header-title {
    font-size: 1rem;
    font-weight: bold;
}

.alphabets-box {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alphabets {
    font-size: 10px;
    font-weight: 600;
    color: white;
}

.date-picker {
    width: 40%;
}
.delay-time-picker {
    width: 50%;
}

.content-align {
    justify-content: start;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 15px !important;
}

.from-container {
    background-color: var(--conatiner--bg-color) !important;
}

.icon-bg-color {
    background-color: var(--icon-bg-color) !important;
}

@media only screen and (max-width: 900px)  {
    .mobile-margin {
        margin-block: 20px !important;
    }
    .date-time-container {
        justify-content: space-between;
    }
    .css-19kzrtu {
        padding: 0 !important;
        padding-block: 24px !important;
    }
    .delay-time-picker {
        width: 100%;
    }
    .content-align {
        justify-content: center;
    }
}