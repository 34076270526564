.dateTimePicker__container {
    display: flex;
    gap: 0.5rem;
    background: #fff;
    border: var(--theme-border);
    border-radius: var(--theme-border-radius);
    height: 45.43px;
    width: 100%;
    margin: 0.5rem 0;

    font-size: 1rem;
    font-weight: 400;
    color: #000;
}
.timePicker__container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: max-content;
    width: 20rem;
    padding: 1rem;

    border-radius: 4px;
    border: 1px solid #dbdddf;
    background: #fff;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.08);

    user-select: none;
}
.timePicker__ctrls_1 {
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    line-height: 2.66;
    letter-spacing: 0.08333em;
    text-transform: uppercase;
}
.timePicker__ctrls_2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 64px;

    width: 100%;
    height: max-content;
    margin-top: auto;
}
.timePicker__ctrls_2 > button {
    background: none;
    appearance: none !important;
    border: none;
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 8px;

    color: #1976d2;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    width: max-content;
    height: max-content;
}
.timePicker__ctrls_2 > button:hover {
    cursor: pointer;
    border-radius: 4px;
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: rgba(25, 118, 210, 0.04);
}
.timePicker__select_window {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 5px;
    width: 100%;
    height: max-content;
    padding: 8px 0;
}
.timePicker__digit_window {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    height: max-content;
    /*  width: 56px; */
    width: 12rem;
    padding: 2px 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.timePicker__digit_window::-webkit-scrollbar {
    width: 0;
}
.timePicker__digit_window::-webkit-scrollbar-track {
    background: #fff;
}
.timePicker__digit_window::-webkit-scrollbar-thumb {
    width: 0;
    background-color: #000;
}
.chevrons {
    border: none !important;
    appearance: none !important;
    padding: 0;
    margin: 0;
    display: grid;
    place-items: center;

    width: 2rem;
    height: 2rem;

    font-size: 1rem;

    border-radius: 50%;
    color: rgba(0, 0, 0, 0.54);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.chevrons:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
}
.chevrons:active {
    background-color: rgba(0, 0, 0, 0.08);
}
.digits {
    font-size: 1.5rem;
}
html .digits > input {
    font-weight: 400;
    font-size: 1.5rem !important;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: center;

    width: 3rem;
    height: 2rem;
    border: none;

    padding: 0 !important;
    margin: 0 !important;
}
html .digits > input:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}
html .digits > input:focus-visible {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}
