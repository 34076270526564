.step-frist {
    margin-right: 0px !important;
    padding-inline: 8px !important;
}

.step-last {
    margin-right: 0px !important;
    padding-inline: 8px !important;
}

.common-step {
    padding: 8px 10px!important;
}

.step-font {
    font-size: 16px;
}

.step-icon {
    font-size: 32px;
}

.walk-padding {
    padding-block: 8px;
}

.step-frist-walk-padding {
    padding-block: 16px;
}

.routes-justify {
    justify-content: start;
}

.padding-top {
    padding-top: 8px !important;
}

@media only screen and (max-width: 900px) {
    .step-frist {
        margin-right: 0px !important;
        padding: 8px !important;
    }
    .step-last {
        margin-right: 0px !important;
        padding: 8px !important;
    }
    .step-font {
        font-size: 13px;
    }
    .step-icon {
        font-size: 22px;
    }
    .walk-padding {
        padding-block: 12px;
    }
    .step-frist-walk-padding {
        padding-block: 12px;
    }
    .routes-justify {
        justify-content: center;
    }
    .padding-top {
        padding-top: 0 !important;
    }
}