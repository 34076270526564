.alphabets-box {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alphabets {
    font-size: 10px;
    font-weight: 600;
    color: white;
}