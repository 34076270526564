:root {
  --conatiner--bg-color: #F8F9FF;
  --loader--bg-color: #f7f4f1;
  --loader-text-color: #747473;
  --icon-bg-color: #bbb;
  --more-options--bg-color: #f8f8f8;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.primary-color {
  color: #00145B;
}

.more-options-container {
  border-right: 2px solid #fff;
  padding-inline: 20px;
}

.card-font {
  font-size: 14px;
}

.card-icon {
  font-size: 20px;
}

.more-options-forms-bg-color {
  background-color: var(--more-options--bg-color) !important;
}

.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--conatiner--bg-color);
  overflow: hidden;
}

.css-1916zh5-MuiList-root-MuiMultiSectionDigitalClockSection-root {
  overflow-y: auto !important;
}

@media only screen and (max-width: 900px) {
  .font-12 {
    font-size: 11px;
    font-weight: bold;
  }

  .icon-text {
    margin-right: 3px;
    height: 18px;
    width: 18px;
  }

  .more-options-container {
    border-top: 1px solid #fff;
    border-right: 0;
    padding-top: 2px;
    padding-inline: 0px;
    margin-top: 10px !important;
  }

  .card-font {
    font-size: 14px;
  }

  .card-icon {
    font-size: 15px;
  }
  
  .date-Picker-font {
    font-size: 12px;
  }
}

/* @media only screen and (min-width: 900px) {
	.media-container {
		max-width: 1170px;
	}
} */