.routes-card {
    position: relative;
    margin-top: 25px;
    padding: 20px;
    transition: all 3s ease-out;
    /* -webkit-transition: all 3s;
    -moz-transition: all 3s;
    -o-transition: all 3s;
    -ms-transition: all 3s; */
}

.routes-card:hover {
    transition: all 300ms;
    transform: translate(1em, 0);
    /* -webkit-transform: translate(1em, 0);
    -moz-transform: translate(1em, 0);
    -o-transform: translate(1em, 0);
    -ms-transform: translate(1em, 0); */
    box-shadow: 20px;
    cursor: pointer;
    /* right: -20px; */
}

.active {
    transition: all 3s ease-out;
    right: -110px;
    transform: translate(-130, 0) !important;
}

.routes-card-header {
    width: 100%;
}

.mobile-image {
    height: 30px;
    width: 30px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 900px) {
    .active {
        right: 0;
    }

    .routes-card-header {
        width: 100%;
    }

    .mobile-image {
        height: 20px;
        width: 20px;
    }

    .mobile-text {
        font-size: 10px;
    }
}